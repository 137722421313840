import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../scss/SlidersCarousel.scss";
import { Typography, Tooltip, speedDialActionClasses } from "@mui/material";
import { responsive } from "./utilite";
import Banner1 from "../../images/MovieBanner/Uc.jpg";
import lack from "../../images/moredetails/Lock.svg";
import save from "../../images/moredetails/Save.svg";
import WishedList from "../../images/moredetails/WishedList.svg";
import info from "../../images/moredetails/Info.svg";
import share from "../../images/moredetails/Share.svg";
import clock from "../../images/moredetails/clock.svg";
import play from "../../images/moredetails/play.svg";
import { showPlayButton } from "./showthePlayButton";
import { FaStar } from "react-icons/fa";
import SharePopUpBox from "../../common/sharePopUp/SharePopUp";
import { SlCalender } from "react-icons/sl";

const Slider = ({ data, onAddToWatchlist, type }) => {
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState({});

  const sections = Object.keys(data).filter(
    (key) => key !== "banners" && data[key].length > 0
  );

  const handleSeeCategory = (section) => {
    const languageId = localStorage.getItem("languageId") || "";
    let category = section;
    if (section === "upcoming_movies") {
      category = "upcoming";
    }
    navigate(
      `/movies?language_id=${languageId}&type=${type === "first_show" ? "first_show" : "subscribed"}&category=${category}`
    );
  };

  // const handleSeeMore = (id) => {
  //   navigate(`/moredetails/${id}`, { state: { id } });
  // };

  const handleSeeMore = (id) => {
    const encodedId = btoa(id); // Encode the id using btoa
    navigate(`/moredetails/${encodedId}`, { state: { id: encodedId } });
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getItemsToShow = () => {
    if (window.innerWidth >= 1024) {
      return responsive.desktop.items;
    } else if (window.innerWidth >= 464) {
      return responsive.tablet.items;
    } else {
      return responsive.mobile.items;
    }
  };

  const toggleDescription = (index) => {
    setShowFullDescription((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const convertSecondsToHrMin = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours} Hr ${minutes} Min` : `${minutes} Min`;
  };

  const [shareDetails, setShareDetails] = useState(false);
  const [shareMovie, setShareMovie] = useState("");

  const OpenSharePopUp = (item) => {
    setShareMovie(item?.id);
    setShareDetails(true);
  };

  const CloseSharePopUp = () => {
    setShareDetails(false);
  };

  const isAuthenticated = localStorage.getItem("isAuthenticated");

  // const [currentSlide, setCurrentSlide] = useState(0);
  // const handleAfterChange = (previousSlide, { currentSlide, totalItems }) => {
  //   setCurrentSlide(currentSlide + 5);
  // };

  return (
    <div className="Slider">
      {sections.map((section, index) => (
        <div className="mainSlider-header" key={index}>
          <div className="d-flex justify-content-between align-items-center">
            <Typography
              className="header-text"
              sx={{
                mb: 1,
                paddingLeft: "0 !important",
                fontSize: {
                  sm: "16px",
                  md: "32px",
                  lg: "32px",
                },
                textWrap: "nowrap",
              }}
            >
              {section
                ?.replace(/_/g, " ")
                ?.replace(/\b\w/g, (char) => char?.toUpperCase())}
            </Typography>
            <Typography
              className="seemore-btn"
              onClick={() => handleSeeCategory(section)}
              sx={{
                fontSize: {
                  sm: "16px",
                  md: "18px",
                  lg: "18px",
                },
                textWrap: "nowrap",
              }}
            >
              See more
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 18L15 12L9 6"
                    stroke="white"
                    strokeOpacity="0.6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Typography>
          </div>
          <div className="parent">
            <Carousel
              responsive={responsive}
              autoPlay={false}
              swipeable={true}
              draggable={false}
              showDots={false}
              infinite={false}
              arrows={data[section].length > getItemsToShow()}
              partialVisible={false}
              // arrows={
              //   data[section].length > getItemsToShow() &&
              //   currentSlide < data[section].length - getItemsToShow()
              // }
              dotListClass="custom-dot-list-style"
              // afterChange={handleAfterChange}
            >
              {data[section].map((item, idx) => (
                <div
                  className="slider"
                  key={idx}
                  onClick={() =>
                    isAuthenticated === "true"
                      ? handleSeeMore(item?.id)
                      : navigate("/login")
                  }
                >
                  <img
                    src={isValidUrl(item.thumbnail) ? item.thumbnail : Banner1}
                    alt={item?.title || "Default image"}
                  />
                  <div className="image-overlay">
                    <div className="content">
                      <div className="info">
                        <div
                          className="UA-c"
                          style={{ marginBottom: "0.5rem" }}
                        >
                          {item?.Certificate?.name}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={clock}
                            alt="Clock Icon"
                            style={{
                              height: "20px",
                              width: "20px",
                              marginRight: "5px",
                            }}
                          />
                          <span className="duration">
                            {item?.duration
                              ? convertSecondsToHrMin(item?.duration)
                              : ""}
                          </span>
                          <span className="stars">
                            <FaStar
                              color="gold"
                              style={{ marginBottom: "5px", marginLeft: "5px" }}
                            />{" "}
                            {item?.rating}
                          </span>
                        </div>
                      </div>
                      <Typography variant="h6">{item.title}</Typography>
                      <div
                        className="description"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          className={`text ${
                            showFullDescription[idx] ? "full" : "truncated"
                          }`}
                        >
                          {item?.description}
                        </div>
                        {item?.description.split("\n")?.length > 3 &&
                          !showFullDescription[idx] && (
                            <span
                              className="see-more"
                              onClick={() => toggleDescription(idx)}
                            >
                              ....
                            </span>
                          )}
                      </div>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <SlCalender /> Release Date :{" "}
                        {item?.published
                        ? new Date(item?.published_date).toLocaleDateString(
                            "en-GB"
                          )
                        : new Date(item?.scheduled_date).toLocaleDateString(
                            "en-GB"
                          )}
                      </p>
                    </div>
                    <div className="icons">
                      {section === "upcoming_movies" ||
                      !showPlayButton(item) ? (
                        <Tooltip title="Pay/Rent Now" placement="top">
                          <img
                            src={lack}
                            alt="Lock Icon"
                            style={{ height: "40px", width: "40px" }}
                            onClick={() =>
                              isAuthenticated === "true"
                                ? handleSeeMore(item?.id)
                                : navigate("/login")
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Watch Now" placement="top">
                          <img
                            src={play}
                            alt="Lock Icon"
                            style={{ height: "40px", width: "40px" }}
                            onClick={() =>
                              isAuthenticated === "true"
                                ? handleSeeMore(item?.id)
                                : navigate("/login")
                            }
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        title={`${
                          item?.is_saved ? "My Watch List" : "Add Watch List"
                        } `}
                        placement="top"
                      >
                        <img
                          src={item?.is_saved ? WishedList : save}
                          alt="Save Icon"
                          style={{ height: "40px", width: "40px" }}
                          onClick={(event) => {
                            event.preventDefault(); // Prevent default behavior
                            event.stopPropagation(); // Stop event propagation to parent elements
                            onAddToWatchlist(item?.id);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Share" placement="top">
                        <img
                          src={share}
                          alt="Share Icon"
                          style={{ height: "40px", width: "40px" }}
                          onClick={(event) => {
                            event.preventDefault(); // Prevent default behavior
                            event.stopPropagation(); // Stop event propagation to parent elements
                            OpenSharePopUp(item);
                          }}
                        />
                      </Tooltip>

                      <div className="icon-right">
                        <Tooltip title="More Info" placement="top">
                          <img
                            src={info}
                            alt="Info Icon"
                            style={{ height: "40px", width: "40px" }}
                            onClick={(event) => {
                              event.preventDefault(); // Prevent default behavior
                              event.stopPropagation(); // Stop event propagation to parent elements
                              handleSeeMore(item?.id);
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      ))}
      <SharePopUpBox
        open={shareDetails}
        onClose={CloseSharePopUp}
        data={shareMovie}
      ></SharePopUpBox>
    </div>
  );
};

export default Slider;
