import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { uploadImageApi } from "../../services/movieupload";
import { switchtoproducer } from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import "../../scss/Producerdetails.scss";

const ProducerDetails = () => {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    role: "producer",
    pan_number: "",
    id_proof_url: "",
    gst_number: "",
    gst_certificate_url: "",
    cancelled_cheque_url: "",
    bank_account_number: "",
    bank_name: "",
    account_holder_name: "",
    ifsc_code: "",
    terms_and_condition: false,
    address: "",
  });
  const [files, setfiles] = useState(null);
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    const newErrors = { ...errors };
    switch (name) {
      case "production_house_name":
        newErrors.production_house_name = !value?.trim()
          ? "Production House Name is required."
          : "";
        break;
      // case "gst_number":
      //   newErrors.gst_number = !value?.trim()
      //     ? "GST Number is required."
      //     : value.length !== 15
      //     ? "GST Number must be exactly 15 characters."
      //     : !/^[A-Z0-9]{15}$/.test(value)
      //     ? "Invalid GST Number format."
      //     : "";
      //   break;
      case "pan_number":
        newErrors.pan_number = !value?.trim()
          ? "PAN Number is required."
          : value.length !== 10
          ? "PAN Number must be exactly 10 characters."
          : !/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(value)
          ? "Invalid PAN Number format."
          : "";
        break;
      case "bank_account_number":
        newErrors.bank_account_number = !value?.trim()
          ? "Bank Account Number is required."
          : !/^\d{9,18}$/.test(value)
          ? "Bank Account Number must be between 9 to 18 digits."
          : "";
        break;
      case "ifsc_code":
        newErrors.ifsc_code = !value?.trim()
          ? "IFSC Code is required."
          : value.length !== 11
          ? "IFSC Code must be exactly 11 characters."
          : !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)
          ? "Invalid IFSC Code format."
          : "";
        break;
      case "account_holder_name":
        newErrors.account_holder_name = !value?.trim()
          ? "Account Holder's Name is required."
          : "";
        break;
      case "address":
       newErrors.address = !value?.trim()
          ? "Address is required."
          : value.length < 10
          ? "Address must be at least 10 characters."
          : value.length > 250
          ? "Address cannot exceed 250 characters."
          : "";
        break;

      case "id_proof_url":
      // case "cancelled_cheque_url":
      // case "gst_certificate_url":
      // newErrors[name] = !value
      //   ? `${name.replace("_", " ").replace("url", "")} is required.`
      //   : "";
      // break;
      case "terms_and_condition":
        newErrors[name] = !value
          ? `Terms and conditions must be accepted.`
          : "";
        break;
      case "bank_name":
        newErrors.production_house_name = !value?.trim()
          ? "Bank  Name is required."
          : "";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    setFormState((prevState) => {
      const updatedState = { ...prevState, [name]: newValue };
      validateField(name, newValue);
      return updatedState;
    });
    if (name === "address") {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "production_house_name",
      "pan_number",
      "bank_account_number",
      "account_holder_name",
      "ifsc_code",
      "id_proof_url",
      "bank_name",
      "terms_and_condition",
      "address",
    ];

    requiredFields.forEach((field) => {
      const value = formState[field];
      if (field === "terms_and_condition" && !value) {
        newErrors[field] = "Terms and conditions must be accepted.";
      } else if (!value) {
        newErrors[field] = `${field
          .replace(/_/g, " ") // Replace underscores with spaces
          .replace("url", "") // Remove 'url'
          .replace(/\b\w/g, (char) => char.toUpperCase())} is Required.`; // Capitalize first letter of each word
      }
    });

    // Specific validations
    if (formState.pan_number) {
      if (formState.pan_number.length !== 10) {
        newErrors.pan_number = "PAN Number must be exactly 10 characters.";
      } else if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(formState.pan_number)) {
        newErrors.pan_number = "Invalid PAN Number format.";
      }
    }

    if (formState.bank_account_number) {
      if (!/^\d{9,18}$/.test(formState.bank_account_number)) {
        newErrors.bank_account_number =
          "Bank Account Number must be between 9 to 18 digits.";
      }
    }

    if (formState.ifsc_code) {
      if (formState.ifsc_code.length !== 11) {
        newErrors.ifsc_code = "IFSC Code must be exactly 11 characters.";
      } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(formState.ifsc_code)) {
        newErrors.ifsc_code = "Invalid IFSC Code format.";
      }
      if (formState.terms_and_condition === false) {
        newErrors.terms_and_condition = "Please accept terms and conditions!";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (user) {
      setFormState(() => ({
        pan_number: user?.pan_number,
        id_proof_url: user?.id_proof_url,
        gst_number: user?.gst_number,
        gst_certificate_url: user?.gst_certificate_url,
        cancelled_cheque_url: user?.cancelled_cheque_url,
        bank_account_number: user?.bank_account_number,
        bank_name: user?.bank_name,
        account_holder_name: user?.account_holder_name,
        ifsc_code: user?.ifsc_code,
        terms_and_condition: user?.terms_and_condition,
        production_house_name: user?.production_house_name,
        address: user?.address || "",
      }));
    }
  }, [user]);

  const handleContinue = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const payload = {
          name: user.name,
          email: user.email,
          mobile: user?.mobile || "",
          ...formState,
        };
        const response = await dispatch(switchtoproducer(payload));
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          navigate("/home");
        } else {
          throw new Error("Failed to submit personal info");
        }
      } catch (err) {
        toast.error("Please fill all required fields.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileChange = async (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!allowedTypes.includes(file.type)) {
      toast.error(
        "Invalid file type. Only .png, .jpg, .jpeg, and .pdf are allowed."
      );
      return;
    }

    setfiles({
      ...files,
      [name]: file,
    });
    setLoading(true);

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await uploadImageApi(formData);

        if (response && response.data) {
          setFormState((prevState) => ({
            ...prevState,
            [name]: response.data.data?.filePath,
          }));
          toast.success(response?.data?.message);
        } else {
          toast.error("Failed to upload image. Please try again.");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the API returns an error message in the response
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          // General error message if no specific message is returned
          toast.error("An error occurred while uploading the image.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="full-page-background-producer">
      <div className="container">
        <Form onSubmit={handleContinue}>
          <Row
            style={{
              marginLeft: "1rem",
            }}
          >
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Production House</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Production House Name"
                name="production_house_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.production_house_name}
                value={formState.production_house_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.production_house_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">GST Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter GST Number"
                name="gst_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.gst_number}
                value={formState.gst_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.gst_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Pan Card</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter PAN Number"
                name="pan_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.pan_number}
                value={formState.pan_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pan_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                ID Proof (Film chamber/Company registration)
              </Form.Label>
              <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <input
                    type="text"
                    className="mx-0 mt-3"
                    value={
                      document.getElementById("file-upload")?.files?.[0]
                        ?.name || "No file chosen"
                    }
                    disabled
                    style={{
                      borderRadius: "0px 0 0 0px",
                      border: "1px solid #000000",
                      padding: "0.5rem",
                      color: "#000",
                      width: "100%",
                      backgroundColor: "#000000",
                    }}
                  />
                </div>
                <input
                  type="file"
                  name="id_proof_url"
                  id="file-upload"
                  className="file-input-producer"
                  onChange={handleFileChange}
                  style={{
                    display: "none", // Hide the default file input field
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0 4px 4px 0",
                  }}
                  variant="primary"
                  onClick={() => document.getElementById("file-upload").click()}
                >
                  {user?.id_proof_url ? "Change" : "Upload"}
                </Button>
              </div>
              <Form.Text className="text-danger">
                {errors.id_proof_url}
              </Form.Text>
              {user?.id_proof_url && (
                <Form.Text>
                  <a
                    href={user?.id_proof_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "yellow", textDecoration: "none" }}
                  >
                    Click Here to View ID Proof
                  </a>
                </Form.Text>
              )}
            </Col>

            {/* <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                ID Proof (Film chamber/Company regisitration)
             
              </Form.Label>
              <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  type="file"
                  name="id_proof_url"
                  id="file-upload"
                  className="file-input-producer"
                  onChange={handleFileChange}
                  readOnly
                  isInvalid={!!errors.id_proof_url}
                  style={{
                    visibility: "visible",
                    position: "relative",
                    height: 0,
                    width: 0,
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0px  4px 4px 0px",
                  }}
                  variant="primary"
                  onClick={() => document.getElementById("file-upload").click()}
                  className="input-group-append"
                >
                  Upload
                </Button>
              </div>
              <Form.Text className="text-danger">
                {errors.id_proof_url}
              </Form.Text>
            </Col> */}

            {/* <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                Cancelled Cheque <span>(500KB)</span>
              </Form.Label>
              <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <input
                    type="text"
                    className="mx-0 mt-3"
                    value={
                      document.querySelector(
                        'input[name="cancelled_cheque_url"]'
                      )?.files?.[0]?.name || "No file chosen"
                    }
                    disabled
                    style={{
                      borderRadius: "0px 0 0 0px",
                      border: "1px solid #000000",
                      padding: "0.5rem",
                      color: "#000",
                      width: "100%",
                      backgroundColor: "#000000",
                    }}
                  />
                </div>
                <input
                  type="file"
                  name="cancelled_cheque_url"
                  className="file-input-producer"
                  onChange={handleFileChange}
                  style={{
                    display: "none", // Hide the default file input field
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0 4px 4px 0",
                  }}
                  variant="primary"
                  onClick={() =>
                    document
                      .querySelector('input[name="cancelled_cheque_url"]')
                      .click()
                  }
                >
                  {user?.cancelled_cheque_url ? "Change" : "Upload"}
                </Button>
              </div>
              <Form.Text className="text-danger">
                {errors.cancelled_cheque_url}
              </Form.Text>
              {user?.cancelled_cheque_url && (
                <Form.Text>
                  <a
                    href={user?.cancelled_cheque_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "yellow", textDecoration: "none" }}
                  >
                    Click Here to View Cancelled Cheque
                  </a>
                </Form.Text>
              )}
            </Col> */}

            {/* <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                Cancelled Cheque <span>(500KB)</span>
              </Form.Label>
              <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  type="file"
                  name="cancelled_cheque_url"
                  className="file-input-producer"
                  onChange={handleFileChange}
                  isInvalid={!!errors.cancelled_cheque_url}
                  style={{
                    visibility: "visible",
                    position: "relative",
                    height: 0,
                    width: 0,
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                  readOnly
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0px  4px 4px 0px",
                  }}
                  className="input-group-append"
                  variant="primary"
                  onClick={() =>
                    document
                      .querySelector('input[name="cancelled_cheque_url"]')
                      .click()
                  }
                >
                  Upload
                </Button>
              </div>
              <Form.Text className="text-danger">
                {errors.cancelled_cheque_url}
              </Form.Text>
            </Col> */}

            {/* <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                GST Certificate <span>(500KB)</span>
              </Form.Label>
              <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <input
                    type="text"
                    className="mx-0 mt-3"
                    value={
                      document.querySelector(
                        'input[name="gst_certificate_url"]'
                      )?.files?.[0]?.name || "No file chosen"
                    }
                    disabled
                    style={{
                      borderRadius: "0px 0 0 0px",
                      border: "1px solid #000000",
                      padding: "0.5rem",
                      color: "#000",
                      width: "100%",
                      backgroundColor: "#000000",
                    }}
                  />
                </div>
                <input
                  type="file"
                  name="gst_certificate_url"
                  className="file-input-producer"
                  onChange={handleFileChange}
                  style={{
                    display: "none", // Hide the default file input field
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0 4px 4px 0",
                  }}
                  variant="primary"
                  onClick={() =>
                    document
                      .querySelector('input[name="gst_certificate_url"]')
                      .click()
                  }
                >
                  {user?.gst_certificate_url ? "Change" : "Upload"}
                </Button>
              </div>
              <Form.Text className="text-danger">
                {errors.gst_certificate_url}
              </Form.Text>
              {user?.gst_certificate_url && (
                <Form.Text>
                  <a
                    href={user?.gst_certificate_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "yellow", textDecoration: "none" }}
                  >
                    Click Here to View GST Certificate
                  </a>
                </Form.Text>
              )}
            </Col> */}

            {/* <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                GST Certificate
                <span>(500KB)</span>
              </Form.Label>
              <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  type="file"
                  name="gst_certificate_url"
                  className="file-input-producer"
                  onChange={handleFileChange}
                  isInvalid={!!errors.gst_certificate_url}
                  style={{
                    visibility: "visible",
                    position: "relative",
                    height: 0,
                    width: 0,
                  }}
                  readOnly
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0px  4px 4px 0px",
                  }}
                  className="input-group-append"
                  variant="primary"
                  onClick={() =>
                    document
                      .querySelector('input[name="gst_certificate_url"]')
                      .click()
                  }
                >
                  Upload
                </Button>
              </div>
            </Col> */}
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Bank Account Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Bank Account Number"
                name="bank_account_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.bank_account_number}
                value={formState.bank_account_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bank_account_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Bank Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Bank Name"
                name="bank_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.bank_name}
                value={formState.bank_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bank_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">IFSC Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter IFSC Code"
                name="ifsc_code"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.ifsc_code}
                value={formState.ifsc_code || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ifsc_code}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Account Holder's Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Account Holder's Name"
                name="account_holder_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.account_holder_name}
                value={formState.account_holder_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.account_holder_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Form>
                <Form.Group
                  className="mt-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter Address"
                    rows={3}
                    name="address"
                    onChange={handleChange}
                    className="form-control-1"
                    isInvalid={!!errors.address}
                    value={formState.address || ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Col>
            <Col lg={12} md={12} sm={12} className="mt-3">
              <Form.Check
                type="checkbox"
                label={
                  <>
                    I declare that I am the authorized person to upload the
                    movie/s and also agree to all the{" "}
                    <a
                      href="/terms-condition"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "yellow" }}
                    >
                      Terms and Conditions
                    </a>
                    .
                  </>
                }
                name="terms_and_condition"
                onChange={handleChange}
                className="form-check-1"
                isInvalid={!!errors.terms_and_condition}
                checked={formState.terms_and_condition || false}
              />
              <Form.Text className="text-danger">
                {errors.terms_and_condition}
              </Form.Text>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="primary"
            className="mt-3"
            style={{
              background: "#fed530",
              border: "1px solid #fed530",
              color: "black",
              marginLeft: "2rem",
            }}
          >
            Update
          </Button>
          <Button
            onClick={() => navigate("/home")}
            variant="primary"
            className="mt-3"
            style={{
              background: "#fed530",
              border: "1px solid #fed530",
              color: "black",
              marginLeft: "0.5rem",
            }}
          >
            Cancel
          </Button>
        </Form>
      </div>
      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ProducerDetails;
